export default {
    message: {
        "msg1": "Lazada, l'une des plus grandes plateformes de commerce en ligne en Asie du Sud-Est, a été fondée en 2012. Soutenue par l'incubateur de start-up allemand Rocket Internet des frères Samwer, Lazada vise principalement les utilisateurs d'Indonésie, de Malaisie, des Philippines, du Japon et de Thaïlande. Lazada propose principalement des produits tels que l'électronique, les articles ménagers, les jouets, les vêtements de mode et les équipements sportifs. Le 19 mars 2018, le groupe Alibaba a annoncé un investissement supplémentaire de 2 milliards de dollars dans Lazada, la plus grande plateforme de commerce électronique en Asie du Sud-Est, pour son expansion commerciale dans la région. En janvier 2023, Lazada a ouvert un site secondaire japonais, les nouveaux vendeurs étant d'abord inscrits sur le site japonais. En fonction des performances, Lazada envisagera d'inviter les vendeurs à ouvrir d'autres sites.",
        "msg2": "Plateforme d'achat en ligne préférée en Asie du Sud - Est",
        "msg3": "Accès au centre commercial",
        "msg4": "Stratégie commerciale avancée",
        "msg5": "Adopter un modèle mutuellement bénéfique pour créer la prospérité.",
        "msg6": "Les géants de la vente au détail en Asie du Sud - Est",
        "msg7": "Leader du marché, gagner la confiance des consommateurs.",
        "msg8": "Convergence des marques mondiales et locales",
        "msg9": "Offrez - vous une expérience de shopping unique à la fois internationale et locale.",
        "msg10": "À propos",
        "msg11": "Basée à Singapour.",
        "msg12": "Maintenant le premier centre commercial de shopping en ligne en Asie du Sud - Est, il offre un guichet unique pour de nombreuses marques internationales et locales.",
        "msg13": "Nos marchés",
        "msg14": "Actuellement, le marché principal est Singapour, Malaisie, Indonésie, Thaïlande, Vietnam, Chine Taiwan",
        "msg15": "2023 commence à se concentrer sur le développement du marché japonais.",
        "msg16": "Plus de régions, attendez - vous à",
        "msg17": "La Mission",
        "msg18": "Il s'agit de fournir une plate - forme de Trading mondiale où n'importe qui peut échanger n'importe quel article."
    }
}