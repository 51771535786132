import Vue from 'vue'
import axios from 'axios'
import store from '../store'
import { Toast } from 'vant'

const showStatus = status => {
  let message = ''
  switch (status) {
    case 400:
      message = '请求错误(400)'
      break
    case 401:
      message = '未受权，请从新登陆(401)'
      break
    case 403:
      message = '拒绝访问(403)'
      break
    case 404:
      message = '请求出错(404)'
      break
    case 408:
      message = '请求超时(408)'
      break
    case 500:
      message = '服务器错误(500)'
      break
    case 501:
      message = '服务未实现(501)'
      break
    case 502:
      message = '网络错误(502)'
      break
    case 503:
      message = '服务不可用(503)'
      break
    case 504:
      message = '网络超时(504)'
      break
    case 505:
      message = 'HTTP版本不受支持(505)'
      break
    default:
      message = `链接出错(${status})!`
  }
  Toast.fail(`${message}`);
  return `${message}，请检查网络或联系管理员！`
}

const service = axios.create({
  // 联调
  baseURL: process.env.NODE_ENV === 'production' ? `https://api.ladehuc88.top/api` : '/api',
  // baseURL: '/api',
  headers: {
    get: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    },
    post: {
      'Content-Type': 'application/json;charset=utf-8'
    }
  },
  // 是否跨站点访问控制请求
  //withCredentials: true,
  timeout: 30000,
  transformRequest: [(data) => {
    data = JSON.stringify(data)
    return data
  }],
  validateStatus () {
    // 使用async-await，处理reject状况较为繁琐，因此所有返回resolve，在业务代码中处理异常
    return true
  },
  transformResponse: [(data) => {
    if (typeof data === 'string' && data.startsWith('{')) {
      data = JSON.parse(data)
    }
    return data
  }]
})

// 请求拦截器
service.interceptors.request.use((config) => {
  // Toast.loading({
  //   message: '查询中...',
  //   forbidClick: true,
  // });
  const headers = Object.assign({}, config.headers, {'x-access-token' : window.localStorage.getItem('USER_TOKEN')})
  if(window.localStorage.getItem('USER_TOKEN')) {
    config.headers = headers
  }
  return config
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '服务器异常，请联系管理员！'
    return Promise.resolve(error)
})

// 响应拦截器
service.interceptors.response.use((response) => {
    const status = response.status
    let successRes = {}
    let msg = ''
    if (status < 200 || status >= 300) {
        // 处理http错误，抛到业务代码
        msg = showStatus(status)
        if (typeof response.data === 'string') {
            response.data = {msg}
        } else {
            response.data.msg = msg
        }
    }
    else{
      successRes = response.data
    }
    setTimeout(() => {
      Toast.clear()
    }, 500);
    return successRes
}, (error) => {
    // 错误抛到业务代码
    error.data = {}
    error.data.msg = '请求超时或服务器异常，请检查网络或联系管理员！'
    return Promise.resolve(error)
})

export function serviceGet(url,params={}){
    return new Promise((resolve,reject) => {
        service({
            url:url,
            method:'get',
            params:params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export function servicePost(url,params={}){
    return new Promise((resolve,reject) => {
        service({
            url:url,
            method:'post',
            data:params
        }).then(res => {
            resolve(res);
        }).catch(err => {
            reject(err);
        })
    })
}

export function serviceBlob(url,params={}){
  return new Promise((resolve,reject) => {
      service({
          url:url,
          method:'post',
          responseType: 'blob',
          data:params
      }).then(res => {
          resolve(res);
      }).catch(err => {
          reject(err);
      })
  })
}