export default {
    message: {
        "msg1": "Lazada, одна из крупнейших онлайн-платформ для покупок в Юго-Восточной Азии, была основана в 2012 году. Получив поддержку от немецкого стартап-инкубатора Rocket Internet братьев Самвер, основная целевая аудитория Lazada включает пользователей из Индонезии, Малайзии, Филиппин, Японии и Таиланда. Lazada в основном занимается такими товарами, как электроника, товары для дома, игрушки, модная одежда и спортивное оборудование. 19 марта 2018 года Alibaba Group объявила о дополнительных инвестициях в размере 2 миллиардов долларов в Lazada, крупнейшую электронную коммерческую платформу в Юго-Восточной Азии, для расширения бизнеса в регионе. В январе 2023 года Lazada открыла японский подсайт, новые продавцы сначала вступают на японский сайт. Исходя из результатов, Lazada будет рассматривать возможность приглашения продавцов открывать другие сайты.",
        "msg2": "Лучшая онлайн - платформа для покупок в Юго - Восточной Азии",
        "msg3": "Вход в торговый центр",
        "msg4": "Передовые бизнес - стратегии",
        "msg5": "Принять модель взаимной выгоды и создать общее процветание.",
        "msg6": "Крупнейшие розничные компании Юго - Восточной Азии",
        "msg7": "Лидеры рынка завоевывают доверие потребителей.",
        "msg8": "Объединение мировых и местных брендов",
        "msg9": "Мы предлагаем вам единый международный и местный опыт покупок.",
        "msg10": "О",
        "msg11": "Штаб - квартира находится в Сингапуре.",
        "msg12": "В настоящее время является ведущим онлайн - магазином на рынке Юго - Восточной Азии, предлагающим универсальные торговые услуги для многих международных и местных брендов.",
        "msg13": "Наш рынок",
        "msg14": "В настоящее время основными рынками являются Сингапур, Малайзия, Индонезия, Таиланд, Вьетнам, Тайвань, Китай",
        "msg15": "В 2023 году начнется развитие японского рынка.",
        "msg16": "Больше регионов, с нетерпением ждем",
        "msg17": "Миссия",
        "msg18": "Это глобальная торговая платформа, где каждый может торговать любыми предметами."
    }
}