export default {
    message: {
        "msg1": "Lazada, một trong những nền tảng mua sắm trực tuyến lớn nhất tại Đông Nam Á, được thành lập vào năm 2012. Được hỗ trợ bởi trại ấp khởi nghiệp Đức Rocket Internet của anh em Samwer, đối tượng chính của Lazada là người dùng từ Indonesia, Malaysia, Philippines, Nhật Bản và Thái Lan. Lazada chủ yếu kinh doanh các sản phẩm như điện tử, đồ gia dụng, đồ chơi, thời trang và thiết bị thể thao. Vào ngày 19 tháng 3 năm 2018, Tập đoàn Alibaba công bố việc đầu tư thêm 2 tỷ USD vào Lazada, nền tảng thương mại điện tử lớn nhất tại Đông Nam Á, để mở rộng kinh doanh của mình trong khu vực. Vào tháng 1 năm 2023, Lazada đã ra mắt một trang con tại Nhật Bản, với các nhà bán hàng mới trước tiên tham gia trang web tại Nhật Bản. Dựa trên hiệu suất, Lazada sẽ xem xét mời các nhà bán hàng mở các trang web khác.",
        "msg2": "Nền tảng mua sắm trực tuyến hàng đầu Đông Nam Á",
        "msg3": "Vào Mall",
        "msg4": "Chiến lược kinh doanh tiên tiến",
        "msg5": "Tiếp thu mô hình cùng có lợi, cùng sáng tạo phồn vinh.",
        "msg6": "Người khổng lồ bán lẻ ở Đông Nam Á",
        "msg7": "Người dẫn đầu thị trường, giành được sự tin tưởng của người tiêu dùng.",
        "msg8": "Hội tụ thương hiệu toàn cầu và địa phương",
        "msg9": "Cung cấp cho bạn trải nghiệm mua sắm một cửa quốc tế và địa phương.",
        "msg10": "Giới thiệu",
        "msg11": "Trụ sở chính đặt tại Singapore.",
        "msg12": "Hiện là trung tâm mua sắm trực tuyến hàng đầu tại thị trường Đông Nam Á, cung cấp dịch vụ mua sắm một cửa với nhiều thương hiệu quốc tế và địa phương.",
        "msg13": "Thị trường của chúng tôi",
        "msg14": "Thị trường chính hiện nay là Singapore, Malaysia, Indonesia, Thái Lan, Việt Nam, Trung Quốc Đài Loan",
        "msg15": "2023 bắt đầu tập trung phát triển thị trường Nhật Bản.",
        "msg16": "Nhiều khu vực hơn, xin vui lòng chờ đợi",
        "msg17": "Sứ mệnh",
        "msg18": "Đó là cung cấp một nền tảng giao dịch toàn cầu, nơi bất cứ ai cũng có thể giao dịch bất cứ thứ gì."
    }
}