export default {
    message: {
      msg1: 'Lazada(来赞达)，东南亚地区最大的在线购物网站之一，成立于2012年。获得德国创业孵化器RocketInternet桑威尔兄弟(SamwerBrothers)支持，Lazada的目标主要是印尼、马来西亚、菲律宾、日本以及泰国用户。Lazada主要经营3C电子、家居用品、玩具、时尚服饰、运动器材等产品。2018年3月19日，阿里巴巴集团宣布，将向东南亚最大电商平台Lazada追加20亿美元投资，用于该公司在东南亚地区的业务扩张。2023年1月，Lazada开设了日本分站，新卖家会先入驻日本站，根据业绩情况，Lazada会考虑邀请开通其他站点。',
      msg2: '东南亚首选在线购物平台',
      msg3: '进入商城',
      msg4: '先进的商业策略',
      msg5: '采纳互惠互利的模式，共创繁荣。',
      msg6: '东南亚的零售巨头',
      msg7: '市场领先者，赢得消费者的信赖。',
      msg8: '全球与在地品牌汇聚',
      msg9: '为您提供国际与本土的一站式购物体验。',
      msg10: '关于',
      msg11: '总部设在新加坡。',
      msg12: '现已是东南亚市场领先的网上购物商城，提供众多国际和本土品牌的一站式购物服务。',
      msg13: '我们的市场',
      msg14: '目前主营市场是新加坡、马来西亚、印度尼西亚、泰国、越南、中国台湾',
      msg15: '2023开始重点发展日本市场。',
      msg16: '更多地区，敬请期待',
      msg17: '的使命',
      msg18: '是要提供一個全球交易平台，让任何人都能在其中交易任何物品。',
      zhHans: '中文简体',
      en: 'English',
      fr: 'Français',
      ko: '한국어',
      ja: '日本語',
      zhHant: '中文繁体',
      de: 'Deutsch',
      es: 'Español',
      ru: 'Русский язык',
      th: 'ภาษาไทย',
      pt: 'Português',
      vnm: 'Tiếng Việt'
    },
  };
  