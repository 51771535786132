import Vue from 'vue'
import VueRouter from "vue-router"

import index from '@/views/index'


Vue.use(VueRouter)

export default new VueRouter({
  mode: 'hash',
  routes: [
    {
      path: '/',
      redirect:'/index',
      component: index,
    },
    {
      path:'/index',
      component: index
    },
  ]
})