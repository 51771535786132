export default {
    message: {
        "msg1": "Lazada, eine der größten Online-Shopping-Plattformen in Südostasien, wurde 2012 gegründet. Unterstützt von dem deutschen Start-up-Inkubator Rocket Internet der Samwer-Brüder richtet sich Lazadas Hauptzielgruppe hauptsächlich an Nutzer aus Indonesien, Malaysia, den Philippinen, Japan und Thailand. Lazada handelt hauptsächlich mit Produkten wie Elektronik, Haushaltswaren, Spielzeug, Modebekleidung und Sportgeräten. Am 19. März 2018 kündigte die Alibaba Group eine zusätzliche Investition von 2 Milliarden US-Dollar in Lazada an, der größten E-Commerce-Plattform in Südostasien, um deren Geschäftsausweitung in der Region zu unterstützen. Im Januar 2023 eröffnete Lazada eine japanische Unterseite, wobei neue Verkäufer zuerst die japanische Seite betreten. Basierend auf der Leistung wird Lazada erwägen, Verkäufer zur Eröffnung weiterer Seiten einzuladen.",
        "msg2": "Südostasiens bevorzugte Online-Shopping-Plattform",
        "msg3": "Betreten Sie das Einkaufszentrum",
        "msg4": "Fortschrittliche Geschäftsstrategie",
        "msg5": "Ein für beide Seiten vorteilhaftes Modell annehmen und gemeinsam Wohlstand schaffen.",
        "msg6": "Handelsriesen in Südostasien",
        "msg7": "Marktführer, gewinnen das Vertrauen der Verbraucher.",
        "msg8": "Konvergenz globaler und lokaler Marken",
        "msg9": "Wir bieten Ihnen ein Einkaufserlebnis aus einer Hand sowohl international als auch lokal.",
        "msg10": "über",
        "msg11": "Der Hauptsitz befindet sich in Singapur.",
        "msg12": "Es ist heute ein führendes Online-Einkaufszentrum auf dem südostasiatischen Markt und bietet One-Stop-Shopping-Dienstleistungen für zahlreiche internationale und lokale Marken.",
        "msg13": "Unser Markt",
        "msg14": "Derzeit sind die Hauptmärkte Singapur, Malaysia, Indonesien, Thailand, Vietnam und Taiwan, China",
        "msg15": "Ab 2023 konzentrieren wir uns auf die Entwicklung des japanischen Marktes.",
        "msg16": "Weitere Regionen, bleiben Sie dran",
        "msg17": "Unsere Mission",
        "msg18": "Es soll eine globale Handelsplattform bieten, auf der jeder jeden Artikel handeln kann."
    }
}