export default {
  message: {
    msg1: 'Lazada は東南アジア最大のオンライン ショッピング サイトの 1 つで、2012 年に設立されました。ドイツのスタートアップ インキュベーターである RocketInternet Samwer Brothers の支援を受けていいます。Lazada は、主にインドネシア、マレーシア、フィリピン、日本、タイのユーザーをターゲットとしています。 主に3C電子機器、家庭用品、おもちゃ、ファッションアパレル、スポーツ用品などの製品を運営しています。2018年3月19日、アリババグループは東南アジアでの事業拡大のため、東南アジア最大の電子商取引プラットフォームであるLazadaに20億米ドルを追加投資すると発表しました。2023 年 1 月に Lazada は日本支社を開設しました。新規販売者はまず日本のステーションに参入し、実績に基づいて他のサイトの開設への招待を検討します。',
    msg2: '東南アジアで人気のオンライン ショッピング プラットフォーム',
    msg3: 'ストアに入る',
    msg4: '先進的な事業戦略',
    msg5: '相互に利益をもたらすモデルを採用し、共に繁栄を築きましょう。',
    msg6: '東南アジアの小売大手',
    msg7: 'マーケットリーダーは消費者の信頼を勝ち取ります。',
    msg8: 'グローバルブランドとローカルブランドが集結',
    msg9: '国内外のワンストップショッピング体験を提供します。',
    msg10: 'について',
    msg11: 'はシンガポールに本社を置いています。',
    msg12: 'は現在、東南アジアを代表するオンラインショッピングモールであり、多くの国際ブランドや地元ブランドにワンストップ ショッピング サービスを提供しています。',
    msg13: '私たちの市場',
    msg14: '現在の主力市場はシンガポール、マレーシア、インドネシア、タイ、ベトナム、台湾、中国で',
    msg15: '2023年には日本市場の開拓に注力しています。',
    msg16: 'さらに多くのエリアをご覧ください',
    msg17: 'の使命は',
    msg18: '誰もがあらゆるアイテムを取引できるグローバル取引プラットフォームを提供することです。',
    zhHans: '中文简体',
    en: 'English',
    fr: 'Français',
    ko: '한국어',
    ja: '日本語',
    zhHant: '中文繁体',
    de: 'Deutsch',
    es: 'Español',
    ru: 'Русский язык',
    th: 'ภาษาไทย',
    pt: 'Português',
    vnm: 'Tiếng Việt'
  },
};
