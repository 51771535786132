export default {
    message: {
        "msg1": "Lazada는 동남 아시아에서 가장 큰 온라인 쇼핑 플랫폼 중 하나로, 2012년에 설립되었습니다. 독일 스타트업 인큐베이터 로켓인터넷의 샘웨어 형제들의 지원을 받아 Lazada의 주요 타깃 대상은 인도네시아, 말레이시아, 필리핀, 일본 및 태국 사용자입니다. Lazada는 전자 제품, 가정용품, 장난감, 패션 의류 및 스포츠 장비와 같은 제품을 주로 취급합니다. 2018년 3월 19일, 알리바바 그룹은 동남아시아 최대 전자 상거래 플랫폼인 Lazada의 사업 확장을 위해 추가 20억 달러를 투자한다고 발표했습니다. 2023년 1월에는 Lazada가 일본 하위 사이트를 개설했으며, 새로운 판매자는 먼저 일본 사이트에 입점합니다. 성과에 따라 Lazada는 다른 사이트를 개설할 판매자를 초대할 계획입니다.",
        "msg2": "동남아시아 선호 온라인 쇼핑 플랫폼",
        "msg3": "상점에 들어가다",
        "msg4": "선진적인 상업 전략",
        "msg5": "호혜호리의 모델을 채택하여 번영을 함께 창조하다.",
        "msg6": "동남아시아의 거대 소매업체",
        "msg7": "시장 선두주자, 소비자의 신뢰를 얻다.",
        "msg8": "글로벌 및 지역 브랜드 통합",
        "msg9": "국제와 본토의 원스톱 쇼핑 환경을 제공합니다.",
        "msg10": "정보",
        "msg11": "본사는 싱가포르에 있다.",
        "msg12": "현재 이미 동남아시아 시장에서 선두를 달리고 있는 온라인 쇼핑몰로, 많은 국제와 본토 브랜드의 원스톱 쇼핑 서비스를 제공한다.",
        "msg13": "우리 시장",
        "msg14": "현재 주요 영업 시장은 싱가포르, 말레이시아, 인도네시아, 태국, 베트남, 대만이다",
        "msg15": "2023년부터 일본 시장을 중점적으로 발전시킨다.",
        "msg16": "더 많은 지역, 기대해주세요",
        "msg17": "의 사명",
        "msg18": "글로벌 거래 플랫폼을 제공하여 누구나 그 안에서 어떤 물품도 거래할 수 있도록 하는 것이다."
    }
}