import "core-js/modules/es6.regexp.match";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { servicePost, serviceGet } from '@/utils/http';
import api from '@/api/index';
export default {
  data: function data() {
    return {
      jumpUrl: "",
      langName: "",
      langImg: "",
      showLang: false,
      langList: [{
        code: "zh-Hans",
        language: "中文简体",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/07d4a84dc5db87cf14587c149ae458cf.png",
        country_id: 1,
        country_name: "China"
      }, {
        code: "en",
        language: "English",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/64a47eccacd186fbfc569cf64fd78cdb.png",
        country_id: 230,
        country_name: "United States"
      }, {
        code: "fr",
        language: "Français",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/7f4d33b038d571e9ae7524f6b2553e8c.png",
        country_id: 75,
        country_name: "France"
      }, {
        code: "ko",
        language: "한국어",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/5495d26366bb37983a92d9d5844e3d58.png",
        country_id: 120,
        country_name: "South Korea"
      }, {
        code: "ja",
        language: "日本語",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/a88f06b701f922cc5e007e42e11bea06.png",
        country_id: 113,
        country_name: "Japan"
      }, {
        code: "zh-Hant",
        language: "中文繁体",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/26a0fefb2a255f7827484ca5e7c025f3.png",
        country_id: 247,
        country_name: "HongKong"
      }, {
        code: "de",
        language: "Deutsch",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/faedf63d0cf8d4deb473f87d37e95608.png",
        country_id: 59,
        country_name: "Germany"
      }, {
        code: "es",
        language: "Español",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/17671b37b1a765bd51aeb2ada153d568.png",
        country_id: 69,
        country_name: "Spain"
      }, {
        code: "ru",
        language: "Русский язык",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/b93053f2e871e7a8928e46eab109a678.png",
        country_id: 186,
        country_name: "Russia"
      }, {
        code: "th",
        language: "ภาษาไทย",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/fb41f04e5b0af791ce24ed27e9c453d3.png",
        country_id: 215,
        country_name: "Thailand"
      }, {
        code: "pt",
        language: "Português",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/739be0ae8323af619dccf564ac8f664c.png",
        country_id: 179,
        country_name: "Portugal"
      }, {
        code: "vnm",
        language: "Tiếng Việt/",
        country_img: "https://cbec-file.wo-shop.net/uploads/country/20230903/530dd5c216c8842aede2fdcbd138ccfa.png",
        country_id: 237,
        country_name: "Vietnam"
      }],
      scode: '',
      webUrl: '',
      h5Url: ''
    };
  },
  created: function created() {
    var scode = this.$route.query.scode;
    this.scode = scode;
    console.log(this.scode);
    this.getPlatformUrl();
  },
  mounted: function mounted() {
    this.langName = 'English';
    this.langImg = 'https://cbec-file.wo-shop.net/uploads/country/20230903/64a47eccacd186fbfc569cf64fd78cdb.png';
  },
  methods: {
    getPlatformUrl: function getPlatformUrl() {
      var _this = this;

      servicePost(api.getPlatformUrl).then(function (res) {
        _this.webUrl = "".concat(res.data.pc_url, "?scode=").concat(_this.scode);
        _this.h5Url = "".concat(res.data.wap_url, "?scode=").concat(_this.scode);

        if (_this.isMobile()) {
          _this.jumpUrl = _this.h5Url;
        } else {
          _this.jumpUrl = _this.webUrl;
        } // this.jumpUrl  = 'https://chat.ichatlink.net/widget/standalone.html?eid=988ba4cc2320de070a1b0e636702079e&language=en';

      });
    },
    isMobile: function isMobile() {
      var flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
      return flag;
    },
    changeLang: function changeLang(item) {
      this.$i18n.locale = item.code;
      this.langName = item.language;
      this.langImg = item.country_img;
    }
  }
};