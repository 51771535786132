export default {
    message: {
      "msg1": "Lazada, one of the largest online shopping platforms in Southeast Asia, was founded in 2012. Supported by the German startup incubator Rocket Internet's Samwer Brothers, Lazada's main target audience includes users from Indonesia, Malaysia, the Philippines, Japan, and Thailand. Lazada primarily deals in products such as electronics, home goods, toys, fashion apparel, and sports equipment. On March 19, 2018, the Alibaba Group announced an additional $2 billion investment in Lazada, the largest e-commerce platform in Southeast Asia, for its business expansion in the region. In January 2023, Lazada launched a Japanese sub-site, with new sellers first entering the Japanese site. Based on performance, Lazada will consider inviting sellers to open other sites.",
      "msg2": "Southeast Asia's preferred online shopping platform",
      "msg3": "Enter the mall",
      "msg4": "Advanced business strategy",
      "msg5": "Adopting a mutually beneficial model and creating prosperity together.",
      "msg6": "Retail giants in Southeast Asia",
      "msg7": "Market leader, winning the trust of consumers.",
      "msg8": "Convergence of global and local brands",
      "msg9": "We provide you with a one-stop shopping experience both internationally and locally.",
      "msg10": "about",
      "msg11": "The headquarters is located in Singapore.",
      "msg12": "It is now a leading online shopping mall in the Southeast Asian market, providing one-stop shopping services for numerous international and local brands.",
      "msg13": "Our market",
      "msg14": "At present, the main markets are Singapore, Malaysia, Indonesia, Thailand, Vietnam and Taiwan, China",
      "msg15": "Starting from 2023, we will focus on developing the Japanese market.",
      "msg16": "More regions, stay tuned",
      "msg17": "Our mission",
      "msg18": "It is to provide a global trading platform where anyone can trade any item."
    }
}