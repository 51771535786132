export default {
    message: {
        "msg1": "Lazada, una de las mayores plataformas de compras en línea del sudeste asiático, fue fundada en 2012. Respaldada por el incubador de startups alemán Rocket Internet de los hermanos Samwer, el público objetivo principal de Lazada incluye a usuarios de Indonesia, Malasia, Filipinas, Japón y Tailandia. Lazada principalmente comercializa productos como electrónica, artículos para el hogar, juguetes, ropa de moda y equipos deportivos. El 19 de marzo de 2018, el Grupo Alibaba anunció una inversión adicional de 2000 millones de dólares en Lazada, la plataforma de comercio electrónico más grande del sudeste asiático, para su expansión comercial en la región. En enero de 2023, Lazada lanzó un sitio secundario japonés, con nuevos vendedores primero ingresando al sitio japonés. Basándose en el rendimiento, Lazada considerará invitar a los vendedores a abrir otros sitios.",
        "msg2": "La Plataforma de compras en línea preferida en el sudeste asiático",
        "msg3": "Entrar en el centro comercial",
        "msg4": "Estrategia empresarial avanzada",
        "msg5": "Adoptar un modelo de beneficio mutuo para crear prosperidad juntos.",
        "msg6": "Gigantes minoristas en el sudeste asiático",
        "msg7": "Líder del mercado, ganando la confianza de los consumidores.",
        "msg8": "Convergencia de marcas globales y locales",
        "msg9": "Le ofrece una experiencia de compra de ventanilla única internacional y local.",
        "msg10": "Sobre",
        "msg11": "Con sede en Singapur.",
        "msg12": "Ahora es el principal centro comercial en línea en el mercado del sudeste asiático, proporcionando un servicio de compras de ventanilla única para muchas marcas internacionales y locales.",
        "msg13": "Nuestro mercado",
        "msg14": "En la actualidad, los principales mercados son singapur, malasia, indonesia, tailandia, Vietnam y taiwán, china.",
        "msg15": "2023 comienza a centrarse en el desarrollo del mercado japonés.",
        "msg16": "Más regiones, por favor esperen.",
        "msg17": "Misión",
        "msg18": "Es proporcionar una plataforma de comercio global en la que cualquiera pueda comerciar cualquier cosa."
    }
}