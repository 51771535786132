export default {
    message: {
        "msg1": "Lazada，是东南亚最大的在线购物平台之一，成立于2012年。得到德国创业孵化器RocketInternet桑威尔兄弟(SamwerBrothers)的支持，Lazada的主要目标是印度尼西亚、马来西亚、菲律宾、日本以及泰国的用户。Lazada主要经营3C电子、家居用品、玩具、时尚服饰、运动器材等产品。2018年3月19日，阿里巴巴集团宣布，将向东南亚最大电商平台Lazada追加20亿美元投资，用于该公司在东南亚地区的业务扩张。2023年1月，Lazada开设了日本分站，新卖家会先入驻日本站，根据业绩情况，Lazada会考虑邀请开通其他站点。",
        "msg2": "東南亞首選線上購物平臺",
        "msg3": "進入商城",
        "msg4": "先進的商業策略",
        "msg5": "採納互惠互利的模式，共創繁榮。",
        "msg6": "東南亞的零售巨頭",
        "msg7": "市場領先者，贏得消費者的信賴。",
        "msg8": "全球與在地品牌彙聚",
        "msg9": "為您提供國際與本土的一站式購物體驗。",
        "msg10": "關於",
        "msg11": "總部設在新加坡。",
        "msg12": "現已是東南亞市場領先的網上購物商城，提供眾多國際和本土品牌的一站式購物服務。",
        "msg13": "我們的市場",
        "msg14": "現時主營市場是新加坡、馬來西亞、印尼、泰國、越南、中國臺灣",
        "msg15": "2023開始重點發展日本市場。",
        "msg16": "更多地區，敬請期待",
        "msg17": "的使命",
        "msg18": "是要提供一個全球交易平臺，讓任何人都能在其中交易任何物品。"
    }
}