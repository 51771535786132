export default {
    message: {
        "msg1": "Lazada, uma das maiores plataformas de compras online do Sudeste Asiático, foi fundada em 2012. Com o apoio do incubador de startups alemão Rocket Internet dos irmãos Samwer, o principal público-alvo da Lazada inclui usuários da Indonésia, Malásia, Filipinas, Japão e Tailândia. A Lazada lida principalmente com produtos como eletrônicos, artigos para casa, brinquedos, moda e equipamentos esportivos. Em 19 de março de 2018, o Grupo Alibaba anunciou um investimento adicional de US$ 2 bilhões na Lazada, a maior plataforma de comércio eletrônico do Sudeste Asiático, para sua expansão comercial na região. Em janeiro de 2023, a Lazada lançou um sub-site japonês, com novos vendedores primeiro entrando no site japonês. Com base no desempenho, a Lazada considerará convidar vendedores para abrir outros sites.",
        "msg2": "Plataforma de compras online preferida do Sudeste Asiático",
        "msg3": "Entra no centro comercial.",
        "msg4": "Estratégia empresarial avançada",
        "msg5": "Adotar um modelo mutuamente benéfico e criar prosperidade juntos.",
        "msg6": "Gigantes do varejo no Sudeste Asiático",
        "msg7": "Líder de mercado, ganhando a confiança dos consumidores.",
        "msg8": "Convergência de marcas globais e locais",
        "msg9": "Nós fornecemos-lhe uma experiência de compras one-stop internacional e localmente.",
        "msg10": "cerca de",
        "msg11": "A sede está localizada em Singapura.",
        "msg12": "É agora um shopping center on-line líder no mercado do Sudeste Asiático, fornecendo serviços de compras one-stop para inúmeras marcas internacionais e locais.",
        "msg13": "O nosso mercado",
        "msg14": "Atualmente, os principais mercados são Singapura, Malásia, Indonésia, Tailândia, Vietnã e Taiwan, China",
        "msg15": "A partir de 2023, vamos nos concentrar no desenvolvimento do mercado japonês.",
        "msg16": "Mais regiões, fiquem atentos",
        "msg17": "A nossa missão",
        "msg18": "É fornecer uma plataforma de negociação global onde qualquer pessoa pode negociar qualquer item."
    }
}